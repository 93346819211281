window.app = window.app || {};
window.app.mapHandler = (function() {

	var myMap = function() {
    var zoom = $(window).width() < 640 ? 6 : 7.5;

    var map,
	markerLocation = '';

	var options = {
		center: new google.maps.LatLng(47.152212,19.5504053),
		zoom: zoom,
		panControl: true,
		zoomControl: true,
		mapTypeControl: false,
		streetViewControl: true,
		disableDefaultUI: false,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		scrollwheel: false,
		styles: [
			{
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#f5f5f5"
					}
				]
			},
			{
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#616161"
					}
				]
			},
			{
				"elementType": "labels.text.stroke",
				"stylers": [
					{
						"color": "#f5f5f5"
					}
				]
			},
			{
				"featureType": "administrative.country",
				"elementType": "geometry.stroke",
				"stylers": [
					{
						"color": "#E8504D"
					},
					{
						"weight": 1
					}
				]
			},
			{
				"featureType": "administrative.land_parcel",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#bdbdbd"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#eeeeee"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#757575"
					}
				]
			},
			{
				"featureType": "poi.park",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#e5e5e5"
					}
				]
			},
			{
				"featureType": "poi.park",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#9e9e9e"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#ffffff"
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#757575"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#dadada"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#616161"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#9e9e9e"
					}
				]
			},
			{
				"featureType": "transit.line",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#e5e5e5"
					}
				]
			},
			{
				"featureType": "transit.station",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#eeeeee"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#c9c9c9"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#9e9e9e"
					}
				]
			}
		]
	};

	/*
		Load the map then markers
		@param object settings (configuration options for map)
		@return undefined
	*/
	function init(settings) {
		map = new google.maps.Map(document.getElementById( settings.idSelector ), options);
		markerLocation = settings.markerLocation;
		if (settings.store_list == store_data) {
			var stores = store_data;
			loadMarkers(stores, map);
		} else if (settings.store_list == store_data_maltai) {
			var stores = store_data_maltai;
			loadMarkers(stores, map);
		} else if (settings.store_list == store_data_personal) {
            var stores = store_data_personal;
            loadMarkers(stores, map)
        }
	}

	/*
		=======
		MARKERS
		=======
	*/
	var markers = {};
	var markerList = [];

	/*
		Load markers onto the Google Map from a provided array or demo personData (data.js)
		@param array personList [optional] (list of people to load)
		@return undefined
	*/
	function loadMarkers(stores, map) {

		// optional argument of store
		//var stores = store_data;
		var j = 1; // for lorempixel

		for( var i=0; i < stores.length; i++ ) {
			var store = stores[i];
			// if its already on the map, dont put it there again
			// if( markerList.indexOf(store.id) !== -1 ) continue;

			var lat = store.latitude,
				lng = store.longitude,
				markerId = store.id;

			var infoWindow = new google.maps.InfoWindow({
				maxWidth: 550,
				zIndex: 99,
				//pixelOffset: new google.maps.Size(320, 340)
			});

			console.log(map)

			var marker = new google.maps.Marker({
				position: new google.maps.LatLng( lat, lng ),
				title: store.name,
				//markerId: markerId,
				icon: markerLocation,
				map: map
			});

			markers[markerId] = marker;
			markerList.push(store.id);

			var storeInfo = '<div class="store">' +
				'<div class="type" data-store-type="' + store.type + '"></div>' +
				'<p>' + store.zip + ' ' + store.address + '</p>';
				/*'<p class="open"><strong>Nyitvatartás</strong></p>' +
				'<table>' +
				'<tr><th>Hétfő: </th><td>' + store.monday + '</td></tr>' +
				'<tr><th>Kedd: </th><td>' + store.tuesday + '</td></tr>' +
				'<tr><th>Szerda: </th><td>' + store.wednesday + '</td></tr>' +
				'<tr><th>Csütörtök: </th><td>' + store.thursday + '</td></tr>' +
				'<tr><th>Péntek: </th><td>' + store.friday + '</td></tr>' +
				'<tr><th>Szombat: </th><td>' + store.saturday + '</td></tr>' +
				'<tr><th>Vasárnap: </th><td>' + store.sunday + '</td></tr>' +
				'</div>';*/
			var content = [storeInfo].join('');

			google.maps.event.addListener(marker, 'click', (function (marker, content) {
				return function() {
					infoWindow.setContent(content);
					infoWindow.open(map, marker);
				};
			})(marker, content));

			google.maps.event.addListener(infoWindow, 'domready', function() {
				// Reference to the DIV that wraps the bottom of infowindow
				var iwOuter = $('.gm-style-iw');
				var iwBackground = iwOuter.prev();
			});

		}

		var zoomControlDiv = document.createElement('div');
		var zoomControl = new ZoomControl(zoomControlDiv, map);
		zoomControlDiv.index = 1;
		map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(zoomControlDiv);
	}

	function ZoomControl(controlDiv, map) {
		var controlWrapper = document.createElement('div');
		controlWrapper.setAttribute('class', 'gm-custom-controls');
		controlDiv.appendChild(controlWrapper);

		var zoomInButton = document.createElement('div');
		zoomInButton.setAttribute('class', 'gm-custom-zoomin');
		controlWrapper.appendChild(zoomInButton);

		var zoomOutButton = document.createElement('div');
		zoomOutButton.setAttribute('class', 'gm-custom-zoomout');
		controlWrapper.appendChild(zoomOutButton);

		google.maps.event.addDomListener(zoomOutButton, 'click', function() {
			map.setZoom(map.getZoom() - 1);
		});

		google.maps.event.addDomListener(zoomInButton, 'click', function() {
			map.setZoom(map.getZoom() + 1);
		});
	}

	return {
		init: init,
		loadMarkers: loadMarkers,
	};
}();

	function init(){
		console.log('asd')
	}

	custom();

	function custom() {

		if ($('#map').length > 0) {

			// map stuff
			var mapConfig_a = {
				idSelector: 'map',
				markerLocation: '/img/icons/pinbase.png',
				store_list: store_data_maltai,
			};

			var mapConfig_b = {
				idSelector: 'map',
				markerLocation: '/img/icons/pinbase.png',
				store_list: store_data_maltai,
			};

            var mapConfig_c = {
                idSelector: 'map',
                markerLocation: '/img/icons/pinbase.png',
                store_list: store_data_personal,
            };

			myMap.init( mapConfig_b );

			$('.tab-1').on('click', function(){
				if ( $(this).hasClass('active') ) return
				myMap.init( mapConfig_c );
				$(this).addClass('active');
				$('.tab-2, .tab-3').removeClass('active');
			});
			// $('.tab-2').on('click', function(){
			// 	if ( $(this).hasClass('active') ) return
			// 	myMap.init( mapConfig_b );
			// 	$(this).addClass('active');
			// 	$('.tab-1, .tab-3').removeClass('active');
			// });
            $('.tab-3').on('click', function(){
            	if ( $(this).hasClass('active') ) return
                myMap.init( mapConfig_b );
                $(this).addClass('active');
                $('.tab-1, .tab-2').removeClass('active');
            });

		}
	}

	return {
		init: init
	};

})();

